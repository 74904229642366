import "react-circular-progressbar/dist/styles.css"
import React, { useEffect, useState } from "react"
import last from "lodash/last"
import moment from "moment"
import sample from "lodash/sample"
import first from "lodash/first"
import without from "lodash/without"
import { useDispatch, useSelector } from "react-redux"

import Answer from "./answer"
import ChoicesComponent from "./choices"
import HintComponent from "./hint"
import ImageOnCorrect from "./imageOnCorrect"
import Prompt from "./prompt"
import Unlockables, { UnlockableName } from "../../services/unlockables"
import { Choice, ConceptToCharactersTypes, Question, QuestionContent, QuestionContentImage, QuestionType } from "../../hasura/slices/sequence"
import { Hint } from "../../services/hint"
import { Sound } from "../../services/audio"
import { UserEvent } from "../../lib/userEventTypes"
import { answerFullySolved } from "./lib/helpers"
import { imageFor, preloadImage, soundEffectsEnabled } from "../../lib/helpers"
import { insertUserEventAction, userSelector, UserState } from "../../hasura/slices/user"
import { usePrevious } from "../../hooks/usePrevious"

// @ts-ignore
import GAMEPLAY_CONFIG from "../../../gameplay-config-combined.js"

interface Props {
  discoveredImageId: (id: number) => void
  isSolved: boolean
  nextQuestion: () => void
  opacity: number
  passageOnCorrect: boolean
  question: Question
  setDisplayPassage: (bool: boolean) => void
  setStreak: (streak: number) => void
  solved: (correct: boolean, isSpeedy: boolean, shouldDisplayImageOnCorrect?: boolean) => void
}

const displayAnswerTypes = [QuestionType.ConceptToRoots, ...ConceptToCharactersTypes]

const speedySecondsFor = (userLevel: number) => {
  for (const range of GAMEPLAY_CONFIG.frontend.speedy.secondsRanges) {
    if (userLevel >= range[0]) return range[1]
  }

  return GAMEPLAY_CONFIG.frontend.speedy.defaultSeconds
}

export default function QuestionComponent(props: Props) {
  const dispatch = useDispatch()

  const { accessToken, user }: UserState = useSelector(userSelector)

  const [clickedHint, setClickedHint] = useState(false)
  const [disableChoices, setDisableChoices] = useState(false)
  const [displayImageOnCorrect, setDisplayImageOnCorrect] = useState(false)
  const [guessedIndexes, setGuessedIndexes] = useState<number[]>([])
  const [hints, setHints] = useState<Hint[]>([])
  const [questionImage, setQuestionImage] = useState<QuestionContentImage | undefined>()
  const [revealedAnswer, setRevealedAnswer] = useState(false)
  const [startedAt, setStartedAt] = useState<moment.Moment | undefined>()

  const content = props.question.content as QuestionContent
  const { answer, choices, images } = content

  const speedySeconds = speedySecondsFor(user?.level || 0)

  /*
    Methods
  */

  useEffect(() => {
    if (!user || !accessToken || !clickedHint) return

    const event = { user_id: user.id, user_event_type_id: UserEvent.ClickedHint }
    dispatch(insertUserEventAction(accessToken, event))
  }, [user, accessToken, clickedHint])

  const isCorrect = (value: string) => answer.some((a: any) => a.value === value)

  const handleGuess = (idx: number) => {
    // stop button mashers
    if (!isCorrect(choices[idx].value)) {
      setDisableChoices(true)
      setTimeout(() => setDisableChoices(false), 1000)
    }
    setGuessedIndexes(guessedIndexes.concat(idx))
  }

  const alreadyGuessed = choices.filter((_: Choice, idx: number) => guessedIndexes.includes(idx))
  const correctlyGuessed = guessedIndexes.filter((idx) => choices[idx]?.correct).map((idx) => choices[idx].value)
  const allCorrect = alreadyGuessed.map((a: Choice) => a.value).every(isCorrect) && !revealedAnswer
  const previousGuessedIndexes = usePrevious(guessedIndexes)
  const isCharacters = ConceptToCharactersTypes.includes(props.question.type as QuestionType)
  const shouldDisplayImageOnCorrect = questionImage && !props.question.passage_id && allCorrect

  /*
    Effects
  */

  useEffect(() => {
    // reset state
    setClickedHint(false)
    setDisplayImageOnCorrect(false)
    setGuessedIndexes([])
    setHints([])
    setQuestionImage(undefined)
    setRevealedAnswer(false)
    setStartedAt(moment())

    // show best image to early level users
    const image = user?.level || 0 < 25 ? first(images) : sample(images)

    if (image && !props.passageOnCorrect) {
      setQuestionImage(image)
      preloadImage(image.key)
    }
  }, [props.question])

  useEffect(() => {
    if (!props.isSolved || !shouldDisplayImageOnCorrect) return

    props.discoveredImageId(questionImage.id)
    setTimeout(() => setDisplayImageOnCorrect(true), 1000)
  }, [props.isSolved])

  useEffect(() => {
    if (!guessedIndexes.length) return

    const guess = choices[isCharacters ? last(guessedIndexes) : without(guessedIndexes, ...(previousGuessedIndexes || []))[0]].value
    const sound = isCorrect(guess) ? Sound.AnswerCorrect : Sound.AnswerIncorrect
    if (!isCorrect(guess)) props.setStreak(0)
    if (soundEffectsEnabled(user)) window.dispatchEvent(new CustomEvent(sound))
    if (!answerFullySolved(answer, correctlyGuessed)) return

    const correct = allCorrect && !clickedHint
    const isSpeedy = correct && moment().diff(startedAt, "seconds") <= speedySeconds && Unlockables.isUnlocked(UnlockableName.SpeedFlame, user)
    props.solved(isSpeedy, correct, !shouldDisplayImageOnCorrect)
  }, [guessedIndexes])

  if (displayImageOnCorrect && questionImage) {
    return <ImageOnCorrect nextQuestion={props.nextQuestion} image={questionImage} caption={questionImage.caption || props.question.display_name} />
  }

  return (
    <div className="w-100 d-flex flex-column h-100">
      <div
        style={{ opacity: props.opacity, transition: `opacity 0.3s` }}
        className="d-flex flex-column flex-grow text-center justify-content-between w-100 overflow scroll"
      >
        {questionImage && props.question.passage_id && (
          <div style={{ maxHeight: "50vh" }} className="mt-5 flex-grow overflow-auto">
            <img style={{ maxHeight: "100%", maxWidth: "100%" }} className="border-radius-10px h-auto w-auto" src={imageFor(questionImage.key)} />
          </div>
        )}

        <div className="d-flex flex-column flex-grow justify-content-center py-4">
          <Prompt hints={hints} isSolved={props.isSolved} question={props.question} />

          {displayAnswerTypes.includes(props.question.type as QuestionType) && (
            <Answer isSolved={props.isSolved} answer={answer} correctlyGuessed={correctlyGuessed} />
          )}
        </div>

        <ChoicesComponent
          choices={choices}
          disable={disableChoices}
          guessedIndexes={guessedIndexes}
          handleGuess={handleGuess}
          hints={hints}
          isSolved={props.isSolved}
          setRevealedAnswer={setRevealedAnswer}
          type={props.question.type as QuestionType}
        />
      </div>

      <div className="d-flex justify-content-end py-4">
        <HintComponent
          autohintDisabled={Boolean(user?.classroom?.teacher?.settings?.autoHintingDisabled)}
          content={content}
          correct={allCorrect}
          hints={hints}
          isSolved={props.isSolved}
          setClickedHint={setClickedHint}
          setHints={setHints}
          type={props.question.type as QuestionType}
          userAccuracy={user?.accuracy || 0}
          userLevel={user?.level || 1}
        />
      </div>
    </div>
  )
}
