import { useEffect } from "react"
import gql from "graphql-tag"
import { useSubscription } from "@apollo/client"
import has from "lodash/has"
import first from "lodash/first"
import moment from "moment"

import { GAME_FIELDS } from "../hasura/queries/game"
import { game_fields } from "../hasura/queries/types/game_fields"

const GAMES_UPDATED = (teacher_id?: string) => gql`
    ${GAME_FIELDS}
    subscription OnGamesUpdated {
        games(where: {teacher_id: {_eq: "${teacher_id}"}}) {
            ...game_fields
        }
    }
`

export type Game = game_fields

export const isGame = (game: any): game is Game => has(game, "join_code")

export const useGames = (callback: (games: Game[]) => void, teacherId?: string) => {
  const subscription = useSubscription(GAMES_UPDATED(teacherId))

  useEffect(() => {
    const games = subscription.data?.games
    if (!games || !isGame(first(games))) return

    const recentGames = games.filter((g: any) => moment(g.created_at).isAfter(moment().subtract(30, "minutes")))
    callback(recentGames)
  }, [teacherId, subscription.data])
}
