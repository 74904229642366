import React from "react"
import { Button } from "reactstrap"

import { PassageByPk_passages_by_pk } from "../../hasura/queries/types/PassageByPk"

interface Props {
  passage: PassageByPk_passages_by_pk
  hidePassage: () => void
}

export default function Passage(props: Props) {
  return (
    <div
      onClick={props.hidePassage}
      className="position-absolute vw-100 vh-100 d-flex align-items-center justify-content-center z-100 flex-column transparent-bg"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="p-4 rounded d-flex flex-column justify-content-between"
        style={{ backgroundColor: "rgba(255,255,255,0.95)", minHeight: "50%", zIndex: 200 }}
      >
        <p style={{ maxWidth: "600px" }} className="m-0 text-xl">
          {props.passage.text}
        </p>

        <div>
          <Button onClick={props.hidePassage} size="lg" className="mt-5 float-right">
            Solve
          </Button>
        </div>
      </div>
    </div>
  )
}
