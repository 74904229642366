import sortBy from "lodash/sortBy"

import { caseInsIncludes } from "../lib/helpers"
import { User_users_by_pk } from "../hasura/queries/types/User"

// @ts-ignore
import unlockables from "../../unlockables"

export interface Unlockable {
  name: string
  level: number
  description: string
  image: string
}

export enum UnlockableName {
  SpeedFlame = "Speed Flame",
  Streaks = "Streaks",
  Dictation = "Dictation",
  Passages = "Passages",
  Trivia = "Trivia",
  MindMap = "Mind Map",
}

export default class Unlockables {
  static shouldUnlock(level: number, alreadyUnlocked: string[]): Unlockable | undefined {
    return sortBy(unlockables, "level").filter((u: Unlockable) => !caseInsIncludes(u.name, alreadyUnlocked) && level >= u.level)[0]
  }

  static isUnlocked(name: UnlockableName, user?: User_users_by_pk) {
    if (!user) return true

    return caseInsIncludes(name, user.unlocked_denormalized.split(","))
  }
}
