"use strict"

module.exports = {
  frontend: {
    read: {
      highlightConceptsSeconds: 2,
      displayNextSeconds: 4,
    },
    speedy: {
      // [[userLevel, seconds]] order by userLevel desc
      secondsRanges: [
        [50, 4],
        [25, 4],
        [5, 4],
      ],
      defaultSeconds: 4,
    },
    hinting: {
      // [[userLevel, [first hint seconds, second hint seconds]]] order by userLevel desc
      secondsRanges: [
        [100, [10, 12, 18]],
        [25, [8, 12, 16]],
        [10, [4, 6, 15]],
        [5, [2, 4, 10]],
      ],
      hideSeconds: 3,
      defaultSeconds: [1, 3, 6],
    },
  },
  backend: {
    curriculumGeneration: {
      passage: {
        quality: {
          minimum: 3,
        },
        difficulty: {
          maximum: 5,
        },
        characters: {
          maximum: 300,
        },
      },
      concept: {
        characters: {
          maximum: 150,
        },
      },      
    },
    sequencing: {
      defaultConcepts: [
        "tricycle",
        "bicycle",
        "biped",
        "quadruped",
        "tripod",
        "arthropod",
        "gastropod",
        "carnivore",
        "herbivore",
        "omnivore",
        "herbicide",
        "homicide",
        "regicide",
        "omnipotent",
      ],
      hardcodedSeenQuestionsCount: 6,
      nonHardcodedQuestionsCount: 12,
      unseenQuestionsCount: 2,
      onlyHardcodedMaxUserLevel: 10,
    },
  },
}