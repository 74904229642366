import React, { useEffect, useState } from "react"
import last from "lodash/last"
import isNil from "lodash/isNil"

import { HIGHLIGHT_IMMEDIATELY, Hint } from "../../services/hint"
import { Prompt, Question, QuestionType, isQuestion } from "../../hasura/slices/sequence"
import { prependSpace } from "../../lib/helpers"

// @ts-ignore
import GAMEPLAY_CONFIG from "../../../gameplay-config-combined.js"

interface Props {
  question?: Question
  prompt?: Prompt[]
  isSolved: boolean
  hints: Hint[]
  isRead?: boolean
}

export default function PromptComponent(props: Props) {
  const { hints, isSolved, isRead, question } = props
  // @ts-ignore
  const prompt: Prompt[] = isRead ? props.prompt : (question!.content.prompt as Prompt[])
  // @ts-ignore
  const correctPrompt = !isRead ? (question!.content.correctPrompt as Prompt[] | null) : null

  const [displayHints, setDisplayHints] = useState(false)
  const [displaySecondaryHints, setDisplaySecondaryHints] = useState(false)
  const [highlightPrompt, setHighlightPrompt] = useState(props.isRead === true)

  const highlightImmediately = isRead || HIGHLIGHT_IMMEDIATELY.includes(question!.type as QuestionType)

  useEffect(() => {
    if (!props.isRead) return

    setTimeout(() => setHighlightPrompt(true), GAMEPLAY_CONFIG.frontend.read.highlightConceptsSeconds * 1000)
  }, [props.isRead])

  useEffect(() => {
    setHighlightPrompt(highlightImmediately)
    setDisplayHints(false)
    setDisplaySecondaryHints(false)
  }, [prompt])

  useEffect(() => {
    const hint = last(hints)
    if (!hint) return

    switch (hint) {
      case Hint.HighlightPrompt:
        setHighlightPrompt(true)
        break
      case Hint.RevealPromptMetadata:
        setDisplayHints(true)
        break
      case Hint.RevealPromptSecondaryMetadata:
        setDisplaySecondaryHints(true)
        break
    }
  }, [hints])

  const displayCorrectPrompt = isSolved && !isNil(correctPrompt)

  const hintSpan = (value: string | null, display: boolean) => (
    <span
      className={`
        text-s text--gray7 prompt-hint l-0 r-0 d-flex justify-content-center nowrap position-absolute 
        ${display ? "transition-m" : "opacity-0"}
      `}
    >
      {value}
    </span>
  )

  return (
    <p id="prompt-container" className={`text-${isRead || question?.passage_id ? "left" : "center"} m-0-auto`}>
      {(displayCorrectPrompt ? correctPrompt : prompt)
        .map((p, idx) => {
          const highlight = p.highlight || p.hide
          const hide = p.hide && !isSolved
          const displayCorrectValue = isSolved && p.correctValue

          const originalValue = prompt[idx].value
          const strikethrough = displayCorrectPrompt && originalValue !== p.value

          return [
            prependSpace(prompt, idx),
            <span
              className={`
                text-xl position-relative
                ${highlightImmediately && highlight ? "bold" : ""}
                ${highlightPrompt && highlight ? "text-primary" : ""}
                ${hide ? "prompt-part-hidden" : "transition-m"}
                ${p.hide && isSolved ? "prompt-part-unhidden" : ""}
              `}
              key={idx}
            >
              {strikethrough && (
                <span style={{ textDecoration: "line-through" }} className="text--gray4">
                  {originalValue}
                </span>
              )}

              {strikethrough && <span> </span>}

              {p.value}

              {!displayCorrectValue && hintSpan(p.hint, displayHints)}

              {
                // @ts-ignore
                !displayCorrectValue && isQuestion(question?.content) && hintSpan(p.secondaryHint, displaySecondaryHints)
              }
            </span>,
          ]
        })
        .reduce(
          (prev: any, curr: any, idx: number) =>
            prev.length
              ? [
                  prev,
                  <span className="text-xl" key={`separator-${idx}`}>
                    {curr[0] && (isRead || question?.passage_id) ? " " : ""}
                  </span>,
                  curr[1],
                ]
              : [curr[1]],
          []
        )}
    </p>
  )
}
