import compact from "lodash/compact"

import { ConceptProgress, SolvedQuestion, progressForQuestion } from "../components/question/lib/conceptProgress"
import { User_users_by_pk } from "../hasura/queries/types/User"
import Unlockables, { Unlockable, UnlockableName } from "./unlockables"
import UserRankCalculator, { UserRank } from "./userRankCalculator"

export interface IntermissionData {
  conceptProgress: ConceptProgress[]
  currentRank: UserRank
  currentStars: number
  level: number
  nextRank?: UserRank
  stars: number
  statistics: Statistic[]
  unlocked?: Unlockable
}

export enum StatisticDirection {
  Decrease,
  Increase,
  Static,
}

export enum Medal {
  Gold,
  Silver,
  Bronze,
}

export enum StatisticName {
  Accuracy = "accuracy",
  Images = "images",
  Passages = "passages",
  Concepts = "words",
  SpeedFlames = "speed flames",
}

export interface Statistic {
  name: StatisticName
  value: number | string
  direction: StatisticDirection
  medal?: Medal
  careerName?: string
  careerValue?: number | string
}

export const intermissionData = (
  user: User_users_by_pk,
  solved: SolvedQuestion[],
  discoveredImageIds: number[],
  isSpeedyCount: number,
  unlocked?: Unlockable
): IntermissionData => {
  const previouslyDiscoveredConceptIds = compact(user?.experience.map((e) => e.concept_id))
  const previouslyDiscoveredPassageIds = compact(user?.experience.map((e) => e.passage_id))
  const previouslyDiscoveredImageIds = compact(user?.images_denormalized.split(",").map(Number))

  const discoveredConceptsCount = solved.filter((s) => s.concept_id && !previouslyDiscoveredConceptIds.includes(s.concept_id)).length
  const discoveredPassagesCount = solved.filter((s) => s.passage_id && !previouslyDiscoveredPassageIds.includes(s.passage_id)).length
  const discoveredImagesCount = discoveredImageIds.filter((id) => !previouslyDiscoveredImageIds.includes(id)).length

  const conceptProgress = compact(solved.map((q) => progressForQuestion(q, user)))
  const accuracy = Math.ceil((solved.filter((s) => s.correct).length * 100) / solved.length)

  const statistics: Statistic[] = [
    {
      name: StatisticName.Concepts,
      value: discoveredConceptsCount,
      direction: discoveredConceptsCount > 0 ? StatisticDirection.Increase : StatisticDirection.Static,
      medal:
        discoveredConceptsCount > 2
          ? Medal.Gold
          : discoveredConceptsCount > 1
          ? Medal.Silver
          : discoveredConceptsCount > 0
          ? Medal.Bronze
          : undefined,
      careerName: "CAREER TOTAL",
      careerValue: previouslyDiscoveredConceptIds.length + discoveredConceptsCount,
    },
    {
      name: StatisticName.Images,
      value: discoveredImagesCount,
      direction: discoveredImagesCount > 0 ? StatisticDirection.Increase : StatisticDirection.Static,
      medal: discoveredImagesCount > 2 ? Medal.Gold : discoveredImagesCount > 1 ? Medal.Silver : discoveredImagesCount > 0 ? Medal.Bronze : undefined,
      careerName: "CAREER TOTAL",
      careerValue: previouslyDiscoveredImageIds.length + discoveredImagesCount,
    },
    {
      name: StatisticName.Passages,
      value: discoveredPassagesCount,
      direction: discoveredPassagesCount > 0 ? StatisticDirection.Increase : StatisticDirection.Static,
      medal:
        discoveredPassagesCount > 2
          ? Medal.Gold
          : discoveredPassagesCount > 1
          ? Medal.Silver
          : discoveredPassagesCount > 0
          ? Medal.Bronze
          : undefined,
      careerName: "CAREER TOTAL",
      careerValue: previouslyDiscoveredPassageIds.length + discoveredPassagesCount,
    },
    {
      name: StatisticName.Accuracy,
      value: `${accuracy}%`,
      direction: accuracy >= (user?.accuracy || 0) ? StatisticDirection.Increase : StatisticDirection.Decrease,
      medal: accuracy === 100 ? Medal.Gold : accuracy > 90 ? Medal.Silver : accuracy > 80 ? Medal.Bronze : undefined,
      careerName: "CAREER AVG",
      careerValue: `${Math.round(user?.accuracy) || 0}%`,
    },
  ]

  if (Unlockables.isUnlocked(UnlockableName.SpeedFlame, user)) {
    statistics.push({
      name: StatisticName.SpeedFlames,
      value: isSpeedyCount,
      medal: isSpeedyCount > 5 ? Medal.Gold : isSpeedyCount > 3 ? Medal.Silver : isSpeedyCount > 1 ? Medal.Bronze : undefined,
      direction: isSpeedyCount > 0 ? StatisticDirection.Increase : StatisticDirection.Static,
    })
  }

  const calculator = new UserRankCalculator(user.stars)

  return {
    conceptProgress,
    currentRank: calculator.rank,
    level: user?.level || 1,
    nextRank: calculator.nextRank(),
    stars: conceptProgress.length,
    statistics,
    currentStars: user?.stars || 0,
    unlocked,
  }
}
