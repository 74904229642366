import cloneDeep from "lodash/cloneDeep"

import { Answer } from "../../../hasura/slices/sequence"

export const answerFullySolved = (answer: Answer[], correctlyGuessed: string[]) =>
  answer.every((_, idx) => answerPartSolved(answer, idx, correctlyGuessed))

export const answerPartSolved = (answer: Answer[], idx: number, correctlyGuessed: string[]) => {
  let cloned = cloneDeep(correctlyGuessed)

  for (const [idx2, a] of answer.entries()) {
    const correct = cloned.includes(a.value)
    if (idx === idx2) return correct || answer[idx].prefill === true

    // important in ConceptToCharacters question types where an answer must be used more than once
    // ex. 'R' in CARNIVORE
    if (correct && !answer[idx2].prefill) {
      cloned.splice(
        cloned.findIndex((v) => v === a.value),
        1
      )
    }
  }

  return false
}
