// tech debt, don't continue to use

export default {
  primary: "#4b62db",
  secondary: "#ffc31a",
  success: "#00d181",
  danger: "#D1273F",
  gray: "#f5f5f5",
  gray2: "#dcdcdc",
  gray3: "#c2c2c2",
  gray4: "#a9a9a9",
  gray5: "#8f8f8f",
  gray6: "#767676",
  gray7: "#5d5d5d",
  gray8: "#434343",
  primary2: "#4B62DB",
  primary3: "#6E7DFA",
  primary4: "#8F98FF",
  primary5: "#AFB5FF",
  secondary6: "#FFF1CC",
}
