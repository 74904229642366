import React from "react"

// @ts-ignore
import scholarIcon from "../../lib/images/scholar.svg"

interface Props {
  idx: number
  display: boolean
}

const content = [
  <span>
    Solve the question by filling in the missing parts. <span className="text--primary bold">Click</span> on the buttons that mean{" "}
    <span className="text--primary bold">three</span> and <span className="text--primary bold">wheel</span>.
  </span>,
  "Hints will help you along. Wordcraft is teaching you to break down words, so if you don't the answer, look for a hint!",
  "Every time you answer a question correctly without clicking hint, you'll win a star.",
  null,
  null,
  "Puzzles test your understanding and improve your reading.",
  null,
]

export default function DemoTooltip(props: Props) {
  const text = content[props.idx]

  return (
    <div
      className={`${
        props.display && text ? "" : "opacity-0"
      } position-absolute b-0 r-0 l-0 mx-auto width-fit-content max-width-600px bg--white rounded mb-2 py-3 px-3 text--gray8 d-flex border border-primary bw-2 transition-s demo-tooltip-mobile`}
    >
      <img className="hide-mobile icon-l mr-3" src={scholarIcon} />
      <p className="text-left text-m m-0">{text}</p>
    </div>
  )
}
