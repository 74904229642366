import React, { useEffect, useState } from "react"
import { Button } from "reactstrap"

import { imageFor } from "../../lib/helpers"
import { QuestionsForUser_questions_for_user_content_QuestionContent_images } from "../../hasura/queries/types/QuestionsForUser"

interface Props {
  image: QuestionsForUser_questions_for_user_content_QuestionContent_images
  nextQuestion: () => void
  caption?: string | null
}

export default function ImageOnCorrect(props: Props) {
  const [opacity, setOpacity] = useState(0)

  const src = imageFor(props.image.key)

  useEffect(() => {
    setTimeout(() => setOpacity(1), 100)
  }, [props.image.key])

  const handleContinue = () => {
    setOpacity(0)
    props.nextQuestion()
  }

  return (
    <div style={{ opacity }} className="w-100 h-100 flex-center flex-column transition-s">
      <div className="flex-center flex-column mb-4" style={{ height: "50%", width: "100%" }}>
        <img className="mh-100 mw-100 h-auto w-auto border-radius-10px" src={src} />

        <p className="pt-2 text-center max-width-600px text-l m-0">{props.caption}</p>
      </div>

      <Button onClick={handleContinue} className="mt-5 width-200px" size="lg" color="primary">
        Continue
      </Button>
    </div>
  )
}
