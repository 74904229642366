import { Button, Modal, ModalBody, ModalFooter, Spinner } from "reactstrap"
import React from "react"

interface Props {
  isOpen: boolean
  setIsOpen: (e: any) => void
  confirm: () => void
  title: string
  body: string
  confirmText: string
  disabled?: boolean
  loading?: boolean
}

export default function DangerModal(props: Props) {
  return (
    <Modal fade={false} centered isOpen={props.isOpen} toggle={() => props.setIsOpen(undefined)}>
      <ModalBody className="text-center min-height-200px flex-center flex-column p-4">
        <p className="text-danger text-l bold">{props.title}</p>

        <p style={{ whiteSpace: "pre-wrap" }} className="text-m text-center mb-0">
          {props.body}
        </p>
      </ModalBody>

      <ModalFooter className="align-items-center flex-column">
        <Button
          className="min-width-200px height-50px flex-center"
          color="danger"
          disabled={props.disabled || props.loading}
          onClick={() => props.confirm()}
          outline
          size="lg"
        >
          {props.loading ? <Spinner color="danger" /> : props.confirmText}
        </Button>

        <p onClick={() => props.setIsOpen(undefined)} className="text-link text-muted">
          Cancel
        </p>
      </ModalFooter>
    </Modal>
  )
}
