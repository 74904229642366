import React from "react"

import colors from "../../lib/colors"
import { Answer } from "../../hasura/slices/sequence"
import { answerPartSolved } from "./lib/helpers"

interface Props {
  answer: Answer[]
  correctlyGuessed: string[]
  isSolved: boolean
}

export default function AnswerComponent(props: Props) {
  const { answer, isSolved, correctlyGuessed } = props

  return (
    <div id="answer-container" className="mb-0 mt-5 text-xxl noselect">
      {answer.map((a, idx) => {
        const isFirst = idx === 0
        const isLast = idx + 1 === answer.length

        const display = isSolved || a.prefill || answerPartSolved(answer, idx, correctlyGuessed)

        const borderColor = display ? colors.success : colors.gray2
        const border = `4px solid ${borderColor}`
        const borderLeft = isSolved && !isFirst ? "" : border
        const borderRight = isSolved && !isLast ? "" : border

        let borderRadius = "answer-border-radius-all"
        if (isSolved) {
          if (isFirst && !isLast) borderRadius = "answer-border-radius-left"
          if (!isFirst && isLast) borderRadius = "answer-border-radius-right"
          if (!isFirst && !isLast) borderRadius = ""
        }

        return (
          <span
            style={{ borderTop: border, borderBottom: border, borderLeft, borderRight }}
            className={`${borderRadius} answer-part ${!display ? "answer-part-hidden" : ""} ${isSolved ? "answer-part-solved" : ""}`}
            key={idx}
          >
            {a.value}
          </span>
        )
      })}
    </div>
  )
}
