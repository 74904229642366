import TextTransition, { presets } from "react-text-transition"
import Countdown from "react-countdown"
import React, { useState } from "react"
import { Badge, Progress } from "reactstrap"
import { navigate } from "gatsby"

import DangerModal from "../common/modals/danger"
import { QuestionsForUser_questions_for_user } from "../../hasura/queries/types/QuestionsForUser"
import { gameIsComplete } from "../../lib/helpers"
import { game_fields, game_fields_positions } from "../../hasura/queries/types/game_fields"

// @ts-ignore
import starIcon from "../../lib/images/star.svg"
// @ts-ignore
import exitIcon from "../../lib/images/exit.png"
// @ts-ignore
import streakIcon from "../../lib/images/streak.svg"
// @ts-ignore
import flameIcon from "../../lib/images/flame.svg"

const MIN_STREAK = 2

export enum GameplayNotification {
  Speedy,
  Correct,
}

interface Props {
  countdownComplete: () => void
  exitDemo: () => void
  gamePosition?: game_fields_positions
  game?: game_fields
  gameStartedAt?: number
  currentIdx: number
  questions: QuestionsForUser_questions_for_user[]
  isDemo: boolean
  isMultiplayer: boolean
  isSolved: boolean
  notifications: GameplayNotification[]
  streak: number
  streaksUnlocked: boolean
  opacity: number
}

export default function PlayHeader(props: Props) {
  const {
    countdownComplete,
    currentIdx,
    exitDemo,
    game,
    gamePosition,
    gameStartedAt,
    isDemo,
    isMultiplayer,
    notifications,
    questions,
    streak,
    streaksUnlocked,
    isSolved,
  } = props

  const [displayConfirmExitModal, setDisplayConfirmExitModal] = useState(false)

  const exit = () => (isDemo ? exitDemo() : navigate("/home"))

  const clickedExit = () => {
    if (currentIdx > 3) {
      setDisplayConfirmExitModal(true)
    } else {
      exit()
    }
  }

  return (
    <div style={{ height: "40px" }} className="d-flex align-items-center flex-row w-100 position-relative zztop">
      <DangerModal
        setIsOpen={setDisplayConfirmExitModal}
        isOpen={displayConfirmExitModal}
        confirm={exit}
        title="Exit before finishing?"
        body="Are you sure you want to exit now? WARNING: you will lose your progress on this level."
        confirmText="Exit"
      />

      <div className="d-flex align-items-center width-150px">
        <img className="icon-s" onClick={clickedExit} src={exitIcon} />
      </div>

      {isMultiplayer ? (
        <div className="flex-grow flex-center">
          <p style={{ width: "100px" }} className="m-0 text-xxl mr-2 bold text-primary text-right mr-2">
            {gamePosition?.score}
          </p>

          {gameStartedAt && (
            <Countdown
              intervalDelay={100}
              date={gameStartedAt}
              onStart={(props) => {
                if (props.completed || (game && gameIsComplete(game))) countdownComplete()
              }}
              onComplete={() => countdownComplete()}
              renderer={(props) => {
                const { minutes, seconds, completed } = props
                const time = completed ? "0:00" : `${minutes}:${seconds < 10 ? 0 : ""}${seconds}`
                return (
                  <div style={{ width: "100px" }} className="text-xxl ml-2">
                    {time}
                  </div>
                )
              }}
            />
          )}
        </div>
      ) : (
        <Progress className="mx-5" id="gameplay-progress" color="success" value={((currentIdx! + (isSolved ? 1 : 0)) / questions.length) * 100} />
      )}

      <div className="d-flex align-items-center justify-content-end width-150px">
        <img style={{ opacity: notifications.includes(GameplayNotification.Speedy) ? 1 : 0 }} className="transition-m icon-s" src={flameIcon} />

        {streak >= MIN_STREAK && streaksUnlocked && (
          <Badge
            id="streak-text"
            color="primary"
            className="ml-2 min-width-100px flex-center text-s text-right border-radius-20px px-3 py-1 semibold"
          >
            <TextTransition direction="down" className="mr-1" springConfig={presets.slow}>
              {streak}
            </TextTransition>{" "}
            streak
            <img className="ml-1 icon-xs" src={streakIcon} />
          </Badge>
        )}
      </div>
    </div>
  )
}
