import React, { useEffect, useState } from "react"
import { Button } from "reactstrap"

import { ReadContent } from "../hasura/slices/sequence"
import { imageFor } from "../lib/helpers"
import Prompt from "./question/prompt"

// @ts-ignore
import GAMEPLAY_CONFIG from "../../gameplay-config-combined.js"

interface Props {
  content: ReadContent
  solved: (isSpeedy: boolean, correct: boolean) => void
  isSolved: boolean
  discoveredImageId: (id: number) => void
}

export default function ReadComponent(props: Props) {
  const [_, setDisplayNext] = useState(false)

  const { image, prompt, imageId } = props.content

  useEffect(() => {
    if (imageId) props.discoveredImageId(imageId)
  }, [imageId])

  useEffect(() => {
    setTimeout(() => setDisplayNext(true), GAMEPLAY_CONFIG.frontend.read.displayNextSeconds * 1000)
  }, [])

  return (
    <div className="h-100 w-100 d-flex align-items-center">
      {image && (
        <div className="flex-even d-flex align-items-center justify-content-end pr-4" style={{ flex: "1 1 0%", overflow: "auto" }}>
          <img style={{ maxHeight: "calc(100vh - 150px)" }} className="mw-100 border-radius-10px" src={imageFor(image)} />
        </div>
      )}

      <div className="flex-even">
        <Prompt isRead prompt={prompt} isSolved={false} hints={[]} />

        <br />

        <div className={image ? "" : "flex-center"}>
          <Button
            className="transition-m m-0-auto width-200px"
            color="primary"
            // disabled={!displayNext}
            onClick={() => props.solved(true, true)}
            size="lg"
            // style={{ opacity: displayNext ? 1 : 0.25 }}
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  )
}
