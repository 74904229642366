import React from "react"

import CONFIG from "../../config"

// @ts-ignore
import GAMEPLAY_CONFIG from "../../../gameplay-config-combined.js"

// @ts-ignore
import copyIcon from "../../lib/images/copy.svg"

interface Props {
  question: any
}

export default function TestingTools(props: Props) {
  return (
    <div id="testing-tools" className="position-absolute r-0 b-0 mb-2 mr-2 d-flex align-items-end z-100">
      <div className="text-center d-flex align-items-end">
        {/* <Button outline color="primary" id="gameplay-config-tooltip">
          Config
        </Button>

        <UncontrolledTooltip
          style={{ overflow: "scroll", height: "90vh" }}
          placement="bottom"
          innerClassName="tooltip-gameplay"
          target="gameplay-config-tooltip"
          trigger="click"
        >
          <pre className="text-left text-white p-2">
            {JSON.stringify(GAMEPLAY_CONFIG, (_: any, v: any) => (v instanceof Array ? JSON.stringify(v) : v), 2)}
          </pre>
        </UncontrolledTooltip> */}

        {props.question.concept_id && (
          <div>
            <a target="_blank" href={`${CONFIG.DOMAIN}/concept?id=${props.question.concept_id}`} className="m-0 text-s">
              /concept?id={props.question.concept_id}
            </a>

            <img onClick={() => navigator.clipboard.writeText(props.question.concept_id)} className="svg-icon hover ml-1" src={copyIcon} />
          </div>
        )}

        {props.question.passage_id && (
          <div>
            <a target="_blank" href={`${CONFIG.DOMAIN}/tagging?id=${props.question.passage_id}`} className="m-0 text-s">
              /passage?id={props.question.passage_id}
            </a>

            <img onClick={() => navigator.clipboard.writeText(props.question.passage_id)} className="svg-icon hover ml-1" src={copyIcon} />
          </div>
        )}
      </div>
    </div>
  )
}
