import extend from "lodash/extend"
import pick from "lodash/pick"

import { Question } from "../../../hasura/slices/sequence"
import { User_users_by_pk } from "../../../hasura/queries/types/User"

export interface SolvedQuestion {
  correct: boolean
  display_name: string
  concept_id: number | null
  passage_id: number | null
  discovered: boolean
}

export enum ConceptStatus {
  Discovered = "Discovered",
  Mastered = "Mastered",
  Improved = "Improved",
}
export interface ConceptProgress {
  concept: string
  stars: number
  status: ConceptStatus
}

export const progressForQuestion = (question: SolvedQuestion, user?: User_users_by_pk): ConceptProgress | undefined => {
  if (question.passage_id || !question.correct) return

  const concept = question.display_name
  const userExperience = user?.experience.find((e) => e.concept_id === question.concept_id)
  if (!userExperience) return { concept, stars: 1, status: ConceptStatus.Discovered }

  const stars = userExperience.correct + 1
  const status = stars < 10 ? ConceptStatus.Improved : ConceptStatus.Mastered
  return { concept, stars, status }
}

export const solvedQuestionFor = (question: Question, correct: boolean): SolvedQuestion =>
  // @ts-ignore
  extend({ correct, discovered: false }, pick(question, ["concept_id", "passage_id", "display_name"]))
