import React, { useEffect, useState } from "react"
import first from "lodash/first"
import last from "lodash/last"
import shuffle from "lodash/shuffle"
import { useSelector } from "react-redux"

import { Choice, ConceptToCharactersTypes, QuestionType } from "../../hasura/slices/sequence"
import { Hint } from "../../services/hint"
import { Sound } from "../../services/audio"
import { UserState, userSelector } from "../../hasura/slices/user"
import { soundEffectsEnabled } from "../../lib/helpers"

interface Props {
  choices: Choice[]
  disable: boolean
  guessedIndexes: number[]
  handleGuess: (choiceIndex: number) => void
  hints: Hint[]
  isSolved: boolean
  setRevealedAnswer: (revealedAnswer: boolean) => void
  type: QuestionType
}

export default function ChoicesComponent(props: Props) {
  const { choices, guessedIndexes, isSolved, hints, disable } = props

  const { user }: UserState = useSelector(userSelector)

  const isCharacters = ConceptToCharactersTypes.includes(props.type)

  const [displayHints, setDisplayHints] = useState(false)
  const [revealAnswerIdx, setRevealAnswerIdx] = useState<number | undefined>()
  const [hideIndexes, setHideIndexes] = useState<number[]>()

  let columns: number

  if (choices.length > 13) {
    columns = 4
  } else if (choices.length > 4) {
    columns = 3
  } else {
    columns = 2
  }

  const gridTemplateColumns = Array(columns).fill("1fr").join(" ")

  /*
    Methods
  */

  const revealAnswer = () => {
    const idx = first(choices.map((c, idx) => (c.correct && !guessedIndexes.includes(idx) ? idx : -1)).filter((idx) => idx !== -1))
    setRevealAnswerIdx(idx)
    props.setRevealedAnswer(true)
    setTimeout(() => setRevealAnswerIdx(undefined), 1000)
  }

  const handleKeyDown = (e: any) => {
    if (!isCharacters || isSolved) return

    const char = e.key.toLowerCase()
    const idx = choices.findIndex((c) => char === c.value[0].toLowerCase())
    if (idx === -1) return

    props.handleGuess(idx)
    if (soundEffectsEnabled(user)) window.dispatchEvent(new CustomEvent(Sound.QuestionMousedown))
  }

  /*
    Effects
  */

  useEffect(() => {
    setDisplayHints(false)
    setHideIndexes([])
  }, [choices])

  useEffect(() => {
    const hint = last(hints)
    if (!hint) return

    switch (hint) {
      case Hint.RevealChoiceMetadata:
        setDisplayHints(true)
        break
      case Hint.RevealCorrectAnswer:
        revealAnswer()
        break
      case Hint.RemoveRedHerrings:
        const hideIndexes = choices.map((c, idx) => (!c.correct && !guessedIndexes.includes(idx) ? idx : -1)).filter((idx) => idx > -1)
        if (hideIndexes.length > 1) {
          setHideIndexes(shuffle(hideIndexes).slice(1))
        } else {
          revealAnswer()
        }
        break
    }
  }, [hints])

  useEffect(() => {
    window.addEventListener("keyup", handleKeyDown)

    return () => {
      window.removeEventListener("keyup", handleKeyDown)
    }
  }, [guessedIndexes])

  return (
    <div style={{ gap: "20px", display: "grid", gridTemplateColumns }}>
      {choices.map((choice, idx) => {
        const guessed = (guessedIndexes.includes(idx) || idx === revealAnswerIdx) && !(isCharacters && choice.correct)

        let color = "white"

        const removeRedHerring = (hideIndexes || []).includes(idx)

        if (guessed) {
          color = choice.correct ? "success" : "danger"
        }

        if (removeRedHerring) {
          color = "gray4"
        }

        return (
          <div
            key={idx}
            onClick={() => props.handleGuess(idx)}
            onMouseDown={() => {
              if (soundEffectsEnabled(user)) window.dispatchEvent(new CustomEvent(Sound.QuestionMousedown))
            }}
            role="button"
            style={{ aspectRatio: isCharacters ? "auto" : "auto" }}
            className={`
              choice-button p-3 position-relative
              bg--${color} 
              pe-${disable || guessed || isSolved || removeRedHerring ? "none" : "auto"}
              border--${guessed ? color : removeRedHerring ? "gray4" : "gray3"} 
            `}
          >
            <p
              key={`value-${idx}`}
              className={`
              choice-button-value text-l mx-1 mb-0
              ${guessed || removeRedHerring ? "text--white" : ""}
              ${props.type === QuestionType.ConceptToDefinition ? "text-l" : "text-xl"}
            `}
            >
              {choice.value}
            </p>

            <p
              key={`hint-${idx}`}
              className={`
                choice-button-hint text-s m-0 mx-1
                ${guessed || removeRedHerring ? "text--white" : "text--gray7"} 
                ${displayHints ? "transition-m" : "opacity-0"}
              `}
            >
              {choice.hint}
            </p>
          </div>
        )
      })}
    </div>
  )
}
