import { QuestionContent, QuestionType } from "../hasura/slices/sequence"

export enum Hint {
  HighlightPrompt = "Highlight Prompt",
  RevealChoiceMetadata = "Reveal Choice Metadata",
  RevealPromptMetadata = "Reveal Prompt Metadata",
  RevealCorrectAnswer = "Reveal Correct Answer",
  RemoveRedHerrings = "Remove Red Herrings",
  RevealPromptSecondaryMetadata = "Reveal Prompt Secondary Metadata",
}

interface HintStep {
  autohint: boolean
  hint: Hint
  repeat: boolean
}

type HintLadder = {
  [key in QuestionType]: HintStep[]
}

const ConceptToCharactersHintLadder = [
  { hint: Hint.HighlightPrompt, autohint: true, repeat: false },
  { hint: Hint.RevealPromptMetadata, autohint: true, repeat: false },
  { hint: Hint.RemoveRedHerrings, autohint: false, repeat: false },
  { hint: Hint.RevealCorrectAnswer, autohint: false, repeat: true },
]

export const HIGHLIGHT_IMMEDIATELY = [QuestionType.GrammarRole, QuestionType.PartOfSpeech, QuestionType.ConceptToPlural]

const HINT_LADDERS: HintLadder = {
  [QuestionType.ConceptToPlural]: [],
  [QuestionType.ConceptToGender]: [],
  [QuestionType.ConceptToRoots]: [
    { hint: Hint.HighlightPrompt, autohint: true, repeat: false },
    { hint: Hint.RevealChoiceMetadata, autohint: true, repeat: false },
    { hint: Hint.RevealPromptMetadata, autohint: true, repeat: false },
    { hint: Hint.RemoveRedHerrings, autohint: false, repeat: false },
    { hint: Hint.RevealCorrectAnswer, autohint: false, repeat: true },
  ],
  [QuestionType.ConceptToCharactersOneCharacter]: ConceptToCharactersHintLadder,
  [QuestionType.ConceptToCharactersOneRoot]: ConceptToCharactersHintLadder,
  [QuestionType.ConceptToCharactersAllRoots]: ConceptToCharactersHintLadder,
  [QuestionType.RootToDefinition]: [
    { hint: Hint.RevealChoiceMetadata, autohint: true, repeat: false },
    { hint: Hint.RemoveRedHerrings, autohint: false, repeat: false },
  ],
  [QuestionType.ConceptToProperty]: [],
  [QuestionType.ConceptToDefinition]: [
    { hint: Hint.HighlightPrompt, autohint: true, repeat: false },
    { hint: Hint.RevealChoiceMetadata, autohint: true, repeat: false },
    { hint: Hint.RevealPromptMetadata, autohint: true, repeat: false },
    { hint: Hint.RemoveRedHerrings, autohint: false, repeat: false },
    { hint: Hint.RevealCorrectAnswer, autohint: false, repeat: true },
  ],
  [QuestionType.ConceptToDefinitionReverse]: [
    { hint: Hint.HighlightPrompt, autohint: true, repeat: false },
    { hint: Hint.RevealChoiceMetadata, autohint: true, repeat: false },
    { hint: Hint.RevealPromptMetadata, autohint: true, repeat: false },
    { hint: Hint.RemoveRedHerrings, autohint: false, repeat: false },
    { hint: Hint.RevealCorrectAnswer, autohint: false, repeat: true },
  ],
  [QuestionType.ReadPassage]: [],
  [QuestionType.FillInTheBlank]: [
    { hint: Hint.RevealChoiceMetadata, autohint: true, repeat: false },
    // { hint: Hint.RevealPromptMetadata, autohint: true, repeat: false },
    { hint: Hint.RemoveRedHerrings, autohint: false, repeat: false },
    { hint: Hint.RevealCorrectAnswer, autohint: false, repeat: true },
  ],
  [QuestionType.TrueOrFalse]: [
    { hint: Hint.HighlightPrompt, autohint: true, repeat: false },
    // { hint: Hint.RevealPromptMetadata, autohint: true, repeat: false },
    { hint: Hint.RevealPromptSecondaryMetadata, autohint: true, repeat: false },
  ],
  [QuestionType.PartOfSpeech]: [
    { hint: Hint.RevealChoiceMetadata, autohint: true, repeat: false },
    { hint: Hint.RevealPromptMetadata, autohint: true, repeat: false },
  ],
  [QuestionType.GrammarRole]: [
    { hint: Hint.RevealChoiceMetadata, autohint: true, repeat: false },
    { hint: Hint.RevealPromptMetadata, autohint: true, repeat: false },
  ],
}

export default class HintService {
  static nextHint(current: Hint[], type: QuestionType, content: QuestionContent, autohint = false): HintStep | undefined {
    let ladder = HINT_LADDERS[type] || []

    const isFillInTheBlank = type === QuestionType.FillInTheBlank
    const isTrueOrFalse = type === QuestionType.TrueOrFalse

    if (isFillInTheBlank && !content.choices.some((p: any) => p.hint)) {
      ladder = ladder.filter((l) => l.hint !== Hint.RevealChoiceMetadata)
    }

    if ((isFillInTheBlank || isTrueOrFalse) && !content.prompt.some((p: any) => p.hint)) {
      ladder = ladder.filter((l) => l.hint !== Hint.RevealPromptMetadata)
    }

    if (isTrueOrFalse && !content.prompt.some((p: any) => p.secondaryHint)) {
      ladder = ladder.filter((l) => l.hint !== Hint.RevealPromptSecondaryMetadata)
    }

    return ladder.filter((hint) => (!current.includes(hint.hint) || hint.repeat) && (!autohint || hint.autohint))[0]
  }
}
