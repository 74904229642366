export default [
  {
    name: "Speed Flame",
    level: 3,
    description: "Beat the timer on a question and receive a speedy flame!",
  },
  {
    name: "Streaks",
    level: 5,
    description: "Answer 3 questions correctly in a row and start a streak!",
  },
  {
    name: "Passages",
    level: 8,
    description: "Answer questions correctly to win passages for your library!",
  },
  {
    name: "Mind Map",
    level: 10,
    description: "View your progress as a mind map during intermission!",
  },
  {
    name: "Trivia",
    level: 20,
    description: "Answer questions based on passages to grow your star count!",
  },
  {
    name: "Dictation",
    level: 30,
    description: "Words will be repeated through your speaker!",
  },
]