import Countdown from "react-countdown"
import React, { useEffect, useState, useRef } from "react"
import isNumber from "lodash/isNumber"
import isUndefined from "lodash/isUndefined"
import moment from "moment"
import { Spinner } from "reactstrap"
import { navigate } from "gatsby"
import { useDispatch, useSelector } from "react-redux"

import "../services/audio"
import CONFIG from "../config"
import DemoTooltip from "../components/question/demoTooltip"
import Layout from "../components/layout"
import Passage from "../components/question/passage"
import PlayHeader, { GameplayNotification } from "../components/play/header"
import QuestionComponent from "../components/question/index"
import Read from "../components/read"
import SEO from "../components/seo"
import TestingTools from "../components/question/testingTools"
import Unlockables, { UnlockableName } from "../services/unlockables"
import { Game, useGames } from "../subscriptions/games"
import { QueryName } from "../hasura/queryNames"
import { SolvedQuestion, solvedQuestionFor } from "../components/question/lib/conceptProgress"
import { Sound } from "../services/audio"
import { completedLevelAction, insertUserSequenceAction, userSelector, UserState } from "../hasura/slices/user"
import { fetchQuestionsAction, isQuestion, Question, ReadContent, SequenceType } from "../hasura/slices/sequence"
import { intermissionData } from "../services/intermission"
import { passageSelector, PassageState } from "../hasura/slices/passage"
import { sequenceSelector, SequenceState, unsetQuestionsAction } from "../hasura/slices/sequence"
import { CurriculumState, curriculumSelector, setIntermissionDataAction } from "../hasura/slices/curriculum"
import { updateGamePositionAction } from "../hasura/slices/game"
import { user_sequences_insert_input } from "../../types/globalTypes"

import {
  adjustGameStartedAt,
  navigateToLeaderboard,
  OLOG,
  preloadImage,
  queryParams,
  searchQueryParams,
  sleep,
  soundEffectsEnabled,
  trackHotjarEvent,
} from "../lib/helpers"

// @ts-ignore
import scrollArrowIcon from "../lib/images/scroll-arrow.svg"
// @ts-ignore
import GAMEPLAY_CONFIG from "../../gameplay-config-combined.js"

const INITIAL_QUESTION_IDX = CONFIG.IS_DEVELOPMENT ? 0 : 0

export default function PlayPage() {
  const dispatch = useDispatch()

  const { accessToken, user, isQuerying }: UserState = useSelector(userSelector)
  const { passage }: PassageState = useSelector(passageSelector)
  const { questions }: SequenceState = useSelector(sequenceSelector)
  const { curriculum }: CurriculumState = useSelector(curriculumSelector)

  const [currentIdx, setCurrentIdx] = useState<number | undefined>()
  const [discoveredImageIds, setDiscoveredImageIds] = useState<number[]>([])
  const [displayPassage, setDisplayPassage] = useState<boolean>(false)
  const [game, setGame] = useState<Game | undefined>()
  const [gameStartedAt, setGameStartedAt] = useState<number | undefined>()
  const [gameStartingIn, setGameStartingIn] = useState<number | undefined>()
  const [isSolved, setIsSolved] = useState<boolean>(false)
  const [isTesting, _] = useState(searchQueryParams("type") === "test" || searchQueryParams("test") || CONFIG.IS_DEVELOPMENT)
  const [multiplayerStarted, setMultiplayerStarted] = useState(false)
  const [multiplayerCompleted, setMultiplayerCompleted] = useState(false)
  const [opacity, setOpacity] = useState(1)
  const [solvedQuestions, setSolvedQuestions] = useState<SolvedQuestion[]>([])
  const [notifications, setNotifications] = useState<GameplayNotification[]>([])
  const [streak, setStreak] = useState(0)
  const [isSpeedyCount, setIsSpeedyCount] = useState(0)
  const [start, setStart] = useState<moment.Moment | undefined>()
  const [isScrollable, setIsScrollable] = useState(false)

  const solvedQuestionsRef: { current: SolvedQuestion[] } = useRef([])
  solvedQuestionsRef.current = solvedQuestions

  const isMultiplayer = queryParams().type === SequenceType.Multiplayer
  const isDemo = queryParams().type === SequenceType.Demo
  const isPrimaryDemo = isDemo && !queryParams().st
  const gamePosition = game?.positions.find((p) => p.user.id === user?.id)
  const questionExists = !isUndefined(currentIdx) && !isUndefined(questions[currentIdx])
  const type = isMultiplayer ? SequenceType.Multiplayer : SequenceType.Train

  const conceptId = currentIdx ? questions[currentIdx].concept_id : null

  /*
    Effects
  */

  useEffect(() => {
    const isScrollableInterval = setInterval(() => {
      const container = document.getElementById("question-container")
      if (!container) return

      const { scrollHeight, scrollTop, clientHeight } = container
      const isSignifigant = scrollHeight - clientHeight > 100
      setIsScrollable(isSignifigant && scrollHeight - scrollTop > clientHeight)
    }, 250)

    return () => {
      clearInterval(isScrollableInterval)
    }
  }, [])

  useGames((games) => {
    if (!isMultiplayer) return

    // @ts-ignore
    setGame(games.find((g) => g.id === queryParams().id))
  }, user?.classroom?.teacher_id)

  useEffect(() => {
    if (multiplayerStarted || (!user && queryParams().type !== "test")) return

    dispatch(fetchQuestionsAction(accessToken || "", curriculum?.id, user?.id))
    setStreak(parseInt(localStorage.getItem(`streak-${user?.id}`) || "", 10) || 0)
    setStart(moment())
    if (user?.level === 1) trackHotjarEvent("started_first_level")
  }, [user, multiplayerStarted])

  useEffect(() => {
    if (!isTesting) return

    window.addEventListener("keydown", skipQuestion)

    return () => {
      window.removeEventListener("keydown", skipQuestion)
    }
  }, [currentIdx, isTesting])

  useEffect(() => {
    if (!questions.length) return

    OLOG(`start ${questions.length} sequence:\n${questions.map((q: any) => `\t${q?.display_name} (${q?.type})`).join("\n")}`)
    setCurrentIdx(INITIAL_QUESTION_IDX)
  }, [questions])

  useEffect(() => {
    if (isNumber(currentIdx) && CONFIG.IS_DEVELOPMENT) console.log("QUESTION", questions[currentIdx])
    setIsSolved(false)
    setNotifications([])
  }, [currentIdx])

  useEffect(() => {
    ;(async () => {
      if (!game || !questions.length || gameStartedAt) return

      const waitSeconds = moment().diff(moment(game.started_at), "seconds", true)
      let ms = waitSeconds > 0 ? 0 : waitSeconds * -1000

      // delay student hackers that refresh to get a new sequence
      if ((gamePosition?.score || 0) > 0) await sleep(5)

      if (ms) setGameStartingIn((moment().unix() + waitSeconds * -1) * 1000)

      setTimeout(() => {
        setGameStartedAt(adjustGameStartedAt(game))
        setMultiplayerStarted(true)
      }, ms)
    })()
  }, [game, questions])

  useEffect(() => {
    if (!user) return

    localStorage.setItem(`streak-${user.id}`, String(streak))
  }, [user, streak, isMultiplayer])

  useEffect(() => {
    return () => {
      dispatch(unsetQuestionsAction())
    }
  }, [])

  /*
    Methods
  */

  const completedLevel = async () => {
    const unlocked = isMultiplayer ? undefined : Unlockables.shouldUnlock(user!.level + 1, user?.unlocked_denormalized.split(",") || [])

    if (!isMultiplayer) {
      dispatch(setIntermissionDataAction(intermissionData(user!, solvedQuestionsRef.current, discoveredImageIds, isSpeedyCount, unlocked)))
      navigate("/intermission")
      saveUserSequence()
    }

    return dispatch(
      completedLevelAction(
        accessToken!,
        user!,
        solvedQuestionsRef.current,
        isMultiplayer ? SequenceType.Multiplayer : SequenceType.Train,
        discoveredImageIds,
        unlocked?.name
      )
    )
  }

  const countdownComplete = () => {
    if (!gamePosition?.score || multiplayerCompleted) return

    setMultiplayerCompleted(true)
    saveUserSequence()
    dispatch(completedLevelAction(accessToken!, user!, solvedQuestionsRef.current, SequenceType.Multiplayer, discoveredImageIds))
    navigateToLeaderboard(game)
  }

  const discoveredImageId = (id: number) => setDiscoveredImageIds(discoveredImageIds.concat(id))

  const hidePassage = () => setDisplayPassage(false)

  const lastQuestion = () => setCurrentIdx(currentIdx! - 1)

  const nextQuestion = async () => {
    const next = questions[currentIdx! + 1]
    const nextnext = questions[currentIdx! + 2]

    if (nextnext && !isQuestion(nextnext!.content) && (nextnext!.content as ReadContent).image) {
      preloadImage((nextnext!.content as ReadContent).image!)
    }

    if (next) {
      return setCurrentIdx(currentIdx! + 1)
    }

    if (isMultiplayer) {
      setCurrentIdx(undefined)
      await completedLevel()
      setSolvedQuestions([])
      dispatch(fetchQuestionsAction(accessToken || "", curriculum?.id, user?.id, true))
      return
    }

    if (user && !isDemo) {
      completedLevel()
    } else {
      exitDemo()
    }
  }

  const playAudio = (conceptId: number) => {
    if (!Unlockables.isUnlocked(UnlockableName.Dictation, user) || CONFIG.IS_DEVELOPMENT || !soundEffectsEnabled(user)) return

    try {
      const audio = new Audio(`https://invisible-college-audio.s3.amazonaws.com/${conceptId}.mp3`)
      audio.play().catch((_: any) => console.log("ERROR playing concept audio"))
    } catch (error) {
      OLOG("ERROR playing audio", true)
    }
  }

  const saveQuestion = (question: Question, correct: boolean) => setSolvedQuestions(solvedQuestions.concat(solvedQuestionFor(question, correct)))

  const saveUserSequence = () => {
    const completed = moment()
    const seconds = moment.duration(completed.diff(start)).asSeconds()
    const entities_denormalized = solvedQuestions.map((q) => (q.passage_id ? `passage-${q.passage_id}` : `concept-${q.concept_id}`)).join()
    const data: user_sequences_insert_input = {
      completed_at: completed.format(),
      entities_denormalized,
      game_type: type,
      seconds,
      started_at: start?.format(),
      user_id: user?.id,
    }
    dispatch(insertUserSequenceAction(accessToken!, data))
  }

  const skipQuestion = (e: any) => {
    if (isUndefined(currentIdx)) return

    if (e.key === "ArrowRight") solved(true, true)
    if (e.key === "ArrowLeft" && currentIdx > 0) lastQuestion()
  }

  const delayedNextQuestion = (ms = 1000) => {
    setTimeout(() => setOpacity(0), ms - 300)
    setTimeout(nextQuestion, ms)
    setTimeout(() => setOpacity(1), ms + 300)
  }

  const solved = (isSpeedy: boolean, correct: boolean, goToNextQuestion?: boolean) => {
    if (goToNextQuestion) delayedNextQuestion()

    if (user) saveQuestion(questions[currentIdx!], correct)

    // between question delay/animations
    setIsSolved(true)

    // update multiplayer score
    if (isMultiplayer && correct && gamePosition) {
      dispatch(updateGamePositionAction(accessToken!, gamePosition.id))
    }

    if (isQuestion(questions[currentIdx!]!.content)) {
      // streak
      if (Unlockables.isUnlocked(UnlockableName.Streaks, user)) setStreak(correct ? streak + 1 : 0)

      // gameplay and concept sounds
      if (conceptId) playAudio(conceptId)

      if (soundEffectsEnabled(user)) {
        window.dispatchEvent(new CustomEvent(correct ? Sound.QuestionCorrect : Sound.QuestionPassed))
      }

      // speedy
      if (isSpeedy) {
        if (soundEffectsEnabled(user)) window.dispatchEvent(new CustomEvent(Sound.QuestionSpeedy))
        setIsSpeedyCount(isSpeedyCount + 1)
      }

      let notifications: GameplayNotification[] = []
      if (correct) notifications.push(GameplayNotification.Correct)
      if (correct && isSpeedy) notifications.push(GameplayNotification.Speedy)
      setNotifications(notifications)
    }
  }

  const exitDemo = () => {
    const id = searchQueryParams("si")
    const type = searchQueryParams("st")
    const to = type ? (type === "root" ? `/setup-game?r=${id}` : `/library?c=${id}`) : "/home"
    navigate(to)
  }

  return (
    <Layout noHeader noStyles>
      <SEO title="Play" />

      {questionExists && (!isMultiplayer || multiplayerStarted) && !isQuerying[QueryName.CompletedLevel] ? (
        <div className="vw-100 h-100 position-fixed bg--gray2">
          {displayPassage && passage && <Passage hidePassage={hidePassage} passage={passage} />}

          <div id="play-container" className="h-100 d-flex align-items-center flex-column m-auto default-max-width">
            <PlayHeader
              countdownComplete={countdownComplete}
              currentIdx={currentIdx}
              exitDemo={exitDemo}
              isSolved={isSolved}
              opacity={opacity}
              game={game}
              gamePosition={gamePosition}
              gameStartedAt={gameStartedAt}
              isDemo={isDemo}
              isMultiplayer={isMultiplayer}
              notifications={notifications}
              questions={questions}
              streak={streak}
              streaksUnlocked={Unlockables.isUnlocked(UnlockableName.Streaks, user)}
            />

            <div id="question-container" className="d-flex w-100 h-100 overflow-scroll">
              <div className={`position-absolute d-flex b-0 transition-s ${isScrollable ? "" : "opacity-0"}`}>
                <p className={`text--primary bold`}>SCROLL</p>
                <img style={{ transform: "rotate(180deg)" }} className="icon-s" src={scrollArrowIcon} />
              </div>

              <div className="flex-even" />

              <div className="position-relative" style={{ flex: 8 }}>
                {isQuestion(questions[currentIdx].content) ? (
                  <QuestionComponent
                    discoveredImageId={discoveredImageId}
                    isSolved={isSolved}
                    nextQuestion={() => delayedNextQuestion(300)}
                    opacity={opacity}
                    passageOnCorrect={questions[currentIdx + 1] && !isQuestion(questions[currentIdx + 1].content)}
                    question={questions[currentIdx]}
                    setDisplayPassage={setDisplayPassage}
                    setStreak={setStreak}
                    solved={solved}
                  />
                ) : (
                  <Read
                    discoveredImageId={discoveredImageId}
                    isSolved={isSolved}
                    content={questions[currentIdx].content as ReadContent}
                    solved={() => {
                      solved(true, true)
                      nextQuestion()
                    }}
                  />
                )}

                {isPrimaryDemo && <DemoTooltip display={!isSolved} idx={currentIdx} />}
              </div>

              <div className="flex-even" />
            </div>

            {isTesting && <TestingTools question={questions[currentIdx]} />}
          </div>
        </div>
      ) : (
        <div className="fullscreen-centered">
          {gameStartingIn && !multiplayerStarted ? (
            <Countdown
              intervalDelay={100}
              date={gameStartingIn}
              renderer={(props) => (
                <div className="text-center">
                  {props.seconds > 0 && <p className="text-s bold gray8 m-0">Game starting in</p>}
                  <p className="text-xxxxl">{props.seconds || "GO!"}</p>
                </div>
              )}
            />
          ) : (
            <Spinner color="primary" size="lg" />
          )}
        </div>
      )}
    </Layout>
  )
}
