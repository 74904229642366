import "react-circular-progressbar/dist/styles.css"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import HintService, { Hint } from "../../services/hint"
import { OLOG, soundEffectsEnabled } from "../../lib/helpers"
import { QuestionContent, QuestionType } from "../../hasura/slices/sequence"
import { Sound } from "../../services/audio"
import { UserState, userSelector } from "../../hasura/slices/user"

// @ts-ignore
import questionMark from "../../lib/images/question-mark.png"

// @ts-ignore
import GAMEPLAY_CONFIG from "../../../gameplay-config-combined.js"

interface Props {
  autohintDisabled: boolean
  content: QuestionContent
  correct: boolean
  hints: Hint[]
  isSolved: boolean
  setClickedHint: (clickedHint: boolean) => void
  setHints: (hints: Hint[]) => void
  type: QuestionType
  userLevel: number
  userAccuracy: number
}

const HINT_DISABLED_SECONDS = 2.5

const hintSecondsFor = (userLevel: number, userAccuracy: number) => {
  const { defaultSeconds, secondsRanges } = GAMEPLAY_CONFIG.frontend.hinting

  if (userAccuracy < 70) return defaultSeconds

  for (const range of secondsRanges) {
    if (userLevel >= range[0]) return range[1]
  }

  return defaultSeconds
}

export default function HintComponent(props: Props) {
  const { hints, isSolved, content, autohintDisabled } = props

  const { user }: UserState = useSelector(userSelector)

  const [disableHintButton, setDisableHintButton] = useState(true)
  const [hintEnabledPercentage, setHintEnabledPercentage] = useState(0)
  const [hintEnabledPercentageInterval, setHintEnabledPercentageInterval] = useState<any | undefined>()
  const [secondsElapsed, setSecondsElapsed] = useState(0)

  const [firstHintSeconds, secondHintSeconds, thirdHintSeconds] = hintSecondsFor(props.userLevel, props.userAccuracy)

  /*
    Effects
  */

  useEffect(() => {
    let isCancelled = false

    setSecondsElapsed(0)
    startHintTrail()

    setInterval(() => {
      if (!isCancelled) setSecondsElapsed((secondsElapsed) => secondsElapsed + 1)
    }, 1000)

    return () => {
      isCancelled = true
    }
  }, [content])

  useEffect(() => {
    if (autohintDisabled || isSolved) return

    const autohint =
      (secondsElapsed === firstHintSeconds && !hints.length) ||
      (secondsElapsed === secondHintSeconds && hints.length === 1) ||
      (secondsElapsed === thirdHintSeconds && hints.length === 2)
    const hint = HintService.nextHint(props.hints, props.type, content, true)
    if (!autohint || !hint) return

    OLOG(`autohint: ${hint.hint}`)
    props.setHints(props.hints.concat(hint.hint))
  }, [hints, secondsElapsed])

  useEffect(() => {
    if (hintEnabledPercentage < 100) return

    setDisableHintButton(false)
    clearInterval(hintEnabledPercentageInterval)
  }, [hintEnabledPercentage])

  /*
    Methods
  */

  const hint = HintService.nextHint(props.hints, props.type, content)

  const handleClickedHint = () => {
    if (soundEffectsEnabled(user)) window.dispatchEvent(new CustomEvent(Sound.QuestionMousedown))

    if (!hint) return

    OLOG(`hint: ${hint.hint}`)
    startHintTrail()

    props.setClickedHint(true)
    props.setHints(props.hints.concat(hint.hint))
  }

  const startHintTrail = () => {
    setDisableHintButton(true)
    setHintEnabledPercentage(0)

    setHintEnabledPercentageInterval(
      setInterval(() => {
        setHintEnabledPercentage((hintEnabledPercentage) => hintEnabledPercentage + 1)
      }, (HINT_DISABLED_SECONDS * 1000) / 100)
    )
  }

  return (
    <div
      className={`
        rounded rounded-circle flex-center transition-m pointer 
        ${disableHintButton || !hint ? "pe-none bg--gray4" : "bg--primary"}
      `}
      onClick={handleClickedHint}
      id="hint-button"
    >
      <img className="noselect" style={{ width: "60%", height: "auto" }} src={questionMark} alt="hint" />
    </div>
  )
}
